import { $ } from './dom.js';
import { SDK_TOKEN } from './constants.js';
import blacklist from './blacklist.json';

import { isAllowEnableTracker } from './business_specification/20220811-allow-enable-tracker';
import { isGladdjpCheckoutPage } from './business_specification/20221020-disallow-tracker-gladdjp';

/** @type {(milliseconds: MilliSeconds) => Promise<void>} */
export const sleep = async (milliseconds) =>
  new Promise((resolve) => setTimeout(resolve, milliseconds));

/** @type {<T>(fn: () => T, max: MilliSeconds) => Promise<T>} */
export const sleepUntil = async (fn, max = 10000) => {
  const start = Date.now();
  while (!fn() && Date.now() - start < max) {
    sleep(100);
  }
  return fn();
};

export const isNil = (v) => v === null || v === (void 0);
export const hasSDK = () => Boolean($('script[src^="https://sdk.handsup"]'));
export const hasCMS = () => [
  $('script[src^="https://cms.handsup"]'),
  $('script[src^="/handsup-cms-sdk.js"]'),
].some(Boolean);


const getQuery = ((url) => {
  const u = new URL(url);
  return (name) => {
    return u.searchParams.get(name);
  };
})(import.meta.url);

const DEBUG_SYMBOL = 'HUT_DEBUG';
export const isDebug = () => Boolean(window[DEBUG_SYMBOL]) ||
  Boolean(getQuery('debug'));

const FORCE_MATOMO_SYMBOL = 'HUT_FORCE_MATOMO';
export const isForceInsertMatomo = () => Boolean(window[FORCE_MATOMO_SYMBOL]) ||
  Boolean(getQuery('force_matomo'));

const COOKIE_DOMAIN_SYMBOL = 'HUT_COOKIE_DOMAIN';
export const getCookieDomain = () => window[COOKIE_DOMAIN_SYMBOL] ||
  getQuery('cookie_domain');

export const getSdkToken = () => getQuery('sdk_token') ?? 'debug-store';

export const isInIframe = () => {
  try {
    return window.self !== window.top;
  } catch {
    return true;
  }
};

export const isHandsupLivewebHost = () => {
  const HANDSUP_LIVEWEB_HOSTS = [
    /^handsup[.]test$/,
    /^handsup[.]shop$/,
    /^dev[.]handsup[.]dev$/,
  ];

  const host = location.hostname;
  return HANDSUP_LIVEWEB_HOSTS.some((pattern) => pattern.test(host));
};

export const isHandsupCmsHost = () => {
  const HANDSUP_CMS_HOSTS = [
    /^cms[.]handsup[.]test$/,
    /^cms-dev[.]handsup[.]dev$/,
    /^cms[.]handsup[.]shop$/,
  ];

  const host = location.hostname;
  return HANDSUP_CMS_HOSTS.some((pattern) => pattern.test(host));
};

export const isHandsupConsumerHost = () => {
  const HANDSUP_CONSUMER_HOSTS = [
    /^consumer[.]handsup[.]test$/,
    /^consumer-dev[.]handsup[.]dev$/,
    /^consumer[.]handsup[.]shop$/,
  ];

  const host = location.hostname;
  return HANDSUP_CONSUMER_HOSTS.some((pattern) => pattern.test(host));
};

export const isOneSevenShopPage = () => {
  if (!isHandsupConsumerHost()) { return false; }

  const ONE_SEVEN_SHOP_PATH = [ /^\/17shop\// ];
  const pathname = location.pathname;
  return ONE_SEVEN_SHOP_PATH.some((pattern) => pattern.test(pathname));
};

export const isMarketplacePage = () => {
  if (!isHandsupConsumerHost()) { return false; }

  const MARKETPLACE_PATH = [ /^\/stores\// ];
  const pathname = location.pathname;
  return MARKETPLACE_PATH.some((pattern) => pattern.test(pathname));
};

export const isInBlacklist = () => Object.keys(blacklist.sdkToken).includes(SDK_TOKEN);
export const isFirstParty = () => !isInIframe() && isHandsupLivewebHost();
export const isEmbedded = () => isInIframe() && isHandsupLivewebHost();
export const isThirdParty = () => !isInIframe() && !isHandsupLivewebHost();
export const isLabelTarget = () => {
  if (isInBlacklist()) { return false; }
  if (isGladdjpCheckoutPage(location.href)) { return false; }

  if (isFirstParty()) { return true; }
  if (isForceInsertMatomo()) { return true; }

  return isThirdParty() && (hasCMS() || isAllowEnableTracker(SDK_TOKEN, location.href));
};

// ref: https://developer.mozilla.org/en-US/docs/Web/API/SubtleCrypto/digest#converting_a_digest_to_a_hex_string
export const hashToVisitorId = async (message) => {
  const msgUint8 = new TextEncoder().encode(message);                           // encode as (utf-8) Uint8Array
  const hashBuffer = await crypto.subtle.digest('SHA-256', msgUint8);           // hash the message
  const hashArray = Array.from(new Uint8Array(hashBuffer));                     // convert buffer to byte array
  const hashHex = hashArray.map((b) => b.toString(16).padStart(2, '0')).join(''); // convert bytes to hex string
  return hashHex.slice(0, 16);
};

/** @type{(n:any) => boolean} */
export const isFiniteNumber = (n) => {
  const inputType = typeof n;
  if (inputType !== 'number' && inputType !== 'string') {
    return false;
  }

  if (n === '') {
    return false;
  }

  return Number.isFinite(Number(n));
};

/**
 * 將輸入值轉化成數字，否則回傳 null
 *
 * @param { any } n
 * @returns { number | null }
 */
export const toFiniteNumberOrNull = (n) => {
  return isFiniteNumber(n) ? Number(n) : null;
};
