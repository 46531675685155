
import { HUT_SITE_ID, HUT_TRACKER_URL, HUT_MATOMO_SRC } from './env';
import { getCookieDomain } from './util';

const _paq = window._paq = window._paq || [];

export const setupMatomoDefaultOptions = () => {
  _paq.push(['setDomains', ['*.handsup.dev']]);
  _paq.push(['enableCrossDomainLinking']);
  /* tracker methods like "setCustomDimension" should be called before "trackPageView" */
  // _paq.push(['trackPageView']);
  _paq.push(['enableLinkTracking']);

  const cookieDomain = getCookieDomain();
  if (cookieDomain) {
    _paq.push(['setCookieDomain', cookieDomain]);
  }
};

// 這邊就會 create visitor id
export const insertMatomoScript = (setupOptionsFn = setupMatomoDefaultOptions) => {
  setupOptionsFn?.();
  _paq.push(['setTrackerUrl', HUT_TRACKER_URL]);
  _paq.push(['setSiteId', HUT_SITE_ID]);
  var d = document, g = d.createElement('script'), s = d.getElementsByTagName('script')[0];
  g.type = 'text/javascript'; g.async = true; g.src = HUT_MATOMO_SRC; s.parentNode.insertBefore(g, s);
};
