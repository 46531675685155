import { isNil } from '../util.js';
import { ensureTracker, findHandsUPPostId, findOneSevenStreamId } from '../runtime.js';

/**
 * @type {(skuId: string | number) => Promise<void>}
 * @deprecated This function has been replaced by `trackHandsupProductClickEvent`
 */
export const trackProductClickEvent = async (skuId) => {
  const tracker = await ensureTracker();
  if (!tracker) { return; }

  const postId = findHandsUPPostId();
  if (isNil(postId)) return;

  const category = 'cms-product';
  const action = 'click';
  const name = `${postId}-${skuId}`;
  const value = 1;

  tracker.trackEvent(category, action, name, value);
};

export const trackHandsupProductClickEvent = trackProductClickEvent;

/** @type {(skuId: string | number) => Promise<void>} */
export const trackOneSevenProductClickEvent = async (skuId) => {
  const tracker = await ensureTracker();
  if (!tracker) { return; }

  const streamId = findOneSevenStreamId();
  if (isNil(streamId)) { return; }

  const category = 'one-seven-shop-product';
  const action = 'click';
  const name = `${streamId}-${skuId}`;
  const value = 1;

  tracker.trackEvent(category, action, name, value);
};

/** @type {(domain: string, option?: setupCookieDomainOption) => Promise<void>} */
export const setupCookieDomain = async (domain, option) => {
  const tracker = await ensureTracker();
  if (!tracker) { return; }

  tracker.setCookieDomain(domain);

  const trackPageView = option?.trackPageView ?? true;
  if (trackPageView) {
    tracker.trackPageView();
  }
};
