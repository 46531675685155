// FingerprintJS-Pro 付費版
// https://dev.fingerprintjs.com/docs/quick-start-guide

import { getTime, addDays } from 'date-fns';
import FingerprintJS from '@fingerprintjs/fingerprintjs-pro';
import expirableLocalStorage from './expirableLocalStorage.js';

const FP_PRO_ID_STORAGE_PATH = 'fp-pro-id'; // 付費版 ID 儲存位置

// Initialize an agent at application startup.
const fpPromise = FingerprintJS.load({ token: process.env.HUT_FINGERPRINT_JS_TOKEN });


/** @type { () => Promise<string?> } */
export const getFingerprintProId = async () => {
  
  if (expirableLocalStorage.isLocalStorageSupported) {
    const lastVisitorId = expirableLocalStorage.get(FP_PRO_ID_STORAGE_PATH);

    if (lastVisitorId.value) { return lastVisitorId.value; }
  }

  try {
    const fp = await fpPromise;

    // 預設 10 秒才會 timeout，怕 User 等太久，先調整為 5 秒，若有 User 反應太久，再進一步調整。
    // ref：https://dev.fingerprintjs.com/docs/js-agent#timeout
    const response = await fp.get({ timeout: 5000 });

    if (!response?.visitorId) {return null;}

    // 'fp-pro' 前綴詞，用於後端分析由 FingerprintJS-Pro 產生
    const newVisitorId = `fp-pro-${response.visitorId}`;

    if (expirableLocalStorage.isLocalStorageSupported) {
      const oneDayExtension = getTime(addDays(Date.now(), 1).getTime());
      expirableLocalStorage.set(FP_PRO_ID_STORAGE_PATH, newVisitorId, oneDayExtension);
    }

    return newVisitorId;
  } catch (err) {
    // 若建立失敗的話，直接刪掉
    if (expirableLocalStorage.isLocalStorageSupported) {
      expirableLocalStorage.remove(FP_PRO_ID_STORAGE_PATH);
    }
    console.error(err);
    return null;
  }
};
