/**
 *
 * @param {string} tag
 * @param {Record<string, any>} attrs
 * @param {(el: HTMLElement) => void} cb
 * @returns {HTMLElement} element
 */
function createElement (tag, attrs, cb) {
  const el = document.createElement(tag);
  Object.assign(el, attrs ?? {});
  cb?.(el);
  return el;
}

export const $el = createElement;

/** @type {(s:string) => HTMLElement?} */
export const $ = (s) => document.querySelector(s);

/** @type {(s:string) => HTMLElement[]} */
export const $$ = (s) => [...document.querySelectorAll(s)];

/** @type {()=>HTMLIFrameElement?} */
export const findHandsupLiveIframe = () => {
  const selectorString = [
    'iframe[src*="/live/"][src*="handsup.dev"]',
    'iframe[src*="/live/"][src*="handsup.shop"]',
    'iframe[src*="/live/"][src*="handsup.test"]',
  ].join(',');

  const founds = $$(selectorString)
    .filter((iframeEl) => getComputedStyle(iframeEl).display !== 'none');

  if (founds.length) { return founds[0]; }
  return null;
};

/** @type {()=>HTMLScriptElement?} */
export const findHandsupSDKScript = () =>
  $('script[src^="https://sdk.handsup"]');
