import { findHandsupLiveIframe, findHandsupSDKScript } from './dom.js';
import {
  hasCMS,
  sleep,
  isHandsupLivewebHost,
  isOneSevenShopPage,
  isMarketplacePage,
  isLabelTarget,
} from './util.js';
import { POST_ID_DIMENSION, STREAM_ID_DIMENSION, SOURCE_ID_DIMENSION } from './constants/custom-dimension.js';

/** @deprecated */
export const appendCrossDomainLinkingUrlParameter = (href) => href;

/** @type {({ trackPageView }?: SetupPostIdByTrackerOption) => Promise<void>} */
export const setupPostIdByTracker = async ({ trackPageView = true } = {}) => {
  const tracker = await ensureTracker();
  if (!tracker) {
    return console.warn('Can not get Matomo tracker');
  }

  const postId = findHandsUPPostId();
  if (postId) {
    tracker.setCustomDimension(POST_ID_DIMENSION, postId);
    if (trackPageView) {
      tracker.trackPageView();
    }
  }
};

/** @type {({ trackPageView }?: SetupStreamIdByTrackerOption) => Promise<void>} */
export const setupStreamIdByTracker = async ({ trackPageView = true } = {}) => {
  const tracker = await ensureTracker();
  if (!tracker) {
    return console.warn('Can not get Matomo tracker');
  }

  const streamId = findOneSevenStreamId();
  if (streamId) {
    tracker.setCustomDimension(STREAM_ID_DIMENSION, streamId);
    if (trackPageView) {
      tracker.trackPageView();
    }
  }
};

/** @type {({ trackPageView }?: SetupSourceIdByTrackerOption) => Promise<void>} */
export const setupSourceIdByTracker = async ({ trackPageView = true } = {}) => {
  const tracker = await ensureTracker();
  if (!tracker) {
    return console.warn('Can not get Matomo tracker');
  }

  const sourceId = findSourceId();
  if (sourceId) {
    tracker.setCustomDimension(SOURCE_ID_DIMENSION, sourceId);
    if (trackPageView) {
      tracker.trackPageView();
    }
  }
};

/** @type {(fn: Function)=>void} */
export const pushInitQueue = (fn) => {
  // `window.matomoAsyncInit` will be called before first tracker init,
  // unless someone called `getAsyncTracker()` in window.matomoAsyncInit()
  const original = window.matomoAsyncInit;
  window.matomoAsyncInit = function matomoAsyncInit () {
    original?.();
    fn?.();
  };
};

/** @type {({ timeout }?: EnsureTrackerOption)=>Promise<MatomoTracker?>} */
export const ensureTracker = async ({ timeout } = {}) => {
  if (!isLabelTarget()) { return; }

  const TRACKER_TIMEOUT_MILLISECONDS = 20000;
  return Promise.race([
    sleep(timeout ?? TRACKER_TIMEOUT_MILLISECONDS).then(() => null),
    new Promise((resolve) => {
      const tracker = window?.Matomo?.getAsyncTracker();
      if (tracker) {return resolve(tracker);}

      pushInitQueue(() => {
        const tracker = window?.Matomo?.getAsyncTracker();
        resolve(tracker);
      });
    }),
  ]);
};

/** @type {()=>string?} */
export const findHandsUPPostId = () => {
  if (isHandsupLivewebHost()) {
    const postId = location.pathname.match(/\/live\/(\d+)/)?.[1];
    if (postId) { return postId; }
  }

  const sdkEl = findHandsupSDKScript();
  if (sdkEl) {
    const sdkPostId = new URL(sdkEl?.src ?? '', location)
      .searchParams.get('post_id');
    if (sdkPostId) { return sdkPostId; }
  }

  const liveIframeEl = findHandsupLiveIframe();
  if (liveIframeEl) {
    const iframeUrl = new URL(liveIframeEl?.src ?? '', location);
    const livePostId = iframeUrl.pathname.replace(/.*\/live\/(\d+)/, '$1');
    if (/^\d+$/.test(livePostId)) { return livePostId; }
  }

  return null;
};

export const findOneSevenStreamId = () => {
  if (isOneSevenShopPage()) {
    const locationUrl = new URL(location);

    const streamId = locationUrl.searchParams.get('stream-id')
      || locationUrl.searchParams.get('stream_id')
      || locationUrl.searchParams.get('streamId')
      || null;

    return streamId;
  }

  return null;
};

export const findSourceId = () => {
  if (hasCMS()) { return 'cms'; }
  if (isHandsupLivewebHost()) { return 'liveweb'; }
  if (isOneSevenShopPage()) { return 'oneSevenShop'; }
  if (isMarketplacePage()) { return 'marketplace'; }
  return null;
};
