import { isFirstParty, isNil, isThirdParty } from '@/util.js';
import { ensureTracker } from '@/runtime.js';
import { LOGIN_ID_DIMENSION, PUBNUB_ID_DIMENSION, POST_ID_DIMENSION } from '@/constants/custom-dimension.js';
import TOPIC from '@/constants/message-topic.js';

const onMessage = async (event) => {
  const msg = event.data;
  if (msg?.type !== 'HUT') { return; }

  const topic = msg?.topic;
  if (!topic) { return; }

  if (topic === TOPIC.ANSWER_LOGIN_ID) {
    /** @type {LoginId | undefined} */
    const body = msg?.body;
    const tracker = await ensureTracker();
    if (isNil(body)) {
      tracker.deleteCustomDimension(LOGIN_ID_DIMENSION);
      return;
    }

    const [loginType, loginId] = Object.entries(body).flat();
    tracker.setCustomDimension(LOGIN_ID_DIMENSION, `${loginType}-${loginId}`);
    tracker.trackPageView();
  }

  if (topic === TOPIC.ANSWER_PUBNUB_ID) {
    const pubnubId = msg?.body;
    const tracker = await ensureTracker();
    if (isNil(pubnubId)) {
      tracker.deleteCustomDimension(PUBNUB_ID_DIMENSION);
      return;
    }

    tracker.setCustomDimension(PUBNUB_ID_DIMENSION, pubnubId);
    tracker.trackPageView();
  }

  if (topic === TOPIC.ANSWER_POST_ID) {
    const postId = msg?.body;
    const tracker = await ensureTracker();
    if (isNil(postId)) {
      tracker.deleteCustomDimension(POST_ID_DIMENSION);
      return;
    }

    tracker.setCustomDimension(POST_ID_DIMENSION, postId);
    tracker.trackPageView();
  }
};

export const setupFirstPartyMessageChannel = () => {
  if (!isFirstParty()) { return; }

  window.addEventListener('message', onMessage);
};

export  const setupThirdPartyMessageChannel = () => {
  if (!isThirdParty()) { return; }

  window.addEventListener('message', onMessage);
};
