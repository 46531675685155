/**
 * 判斷是否允許啟用 Tracker 的店家，條件為：
 * - 若為特定店家，則需額外檢查 網址路徑 為結帳頁面，才允許啟用 Tracker
 * - 若為非特定店家，則允許啟用 Tracker，不需額外檢查
 *
 * Jira: https://17media.atlassian.net/browse/HU-3675
 * Doc: https://docs.google.com/document/d/1qGDjBfnpPK0Wwe4QcM1gMWIFXzh3IxQKeInSifQyUAw/edit
 */


// *** 特定店家(sdk_token) ***
// Fracora (zjy3gn2e)
const isFracora = (sdk_token) => sdk_token === 'zjy3gn2e';
export const isInFracoraCheckoutPage = (urlPath) => {
  const pathPattern = /fracora[.]com[/]Order-Confirm/;
  const isPathMatched = Boolean(urlPath.match(pathPattern));

  return isPathMatched;
}

// チャイハネ (d2z75r69)
const isCayhane = (sdk_token) => sdk_token === 'd2z75r69';
export const isInCayhaneCheckoutPage = (urlPath) => {
  const pathPattern = /cayhane[.]jp[/]shop[/]order[/]order[.]aspx/;
  const isPathMatched = Boolean(urlPath.match(pathPattern));

  const meta = new URL(urlPath);
  const hasOrderId = meta.searchParams.has('order_id');

  return isPathMatched && hasOrderId;
}

// H&D (x2rylgjo)
const isHd = (sdk_token) => sdk_token === 'x2rylgjo';
export const isInHdCheckoutPage = (urlPath) => {
  const pathPattern = /happiness-d[.]com[/]shop[/]order[/]order[.]aspx/;
  const isPathMatched = Boolean(urlPath.match(pathPattern));

  const meta = new URL(urlPath);
  const hasOrderId = meta.searchParams.has('order_id');

  return isPathMatched && hasOrderId;
}

// BRANSHES (z2qngl2m)
const isBranshes = (sdk_token) => sdk_token === 'z2qngl2m';
export const isInBranshesCheckoutPage = (urlPath) => {
  const pathPattern = /branshes[.]com[/]shop[/]order[/]order[.]aspx/;
  const isPathMatched = Boolean(urlPath.match(pathPattern));

  const meta = new URL(urlPath);
  const hasOrderId = meta.searchParams.has('order_id');

  return isPathMatched && hasOrderId;
}

// RANDA (w27g7gx2)
const isRanda = (sdk_token) => sdk_token === 'w27g7gx2';
export const isInRandaCheckoutPage = (urlPath) => {
  const pathPattern = /randa[.]jp[/]shop[/]order[/]order[.]aspx/;
  const isPathMatched = Boolean(urlPath.match(pathPattern));

  const meta = new URL(urlPath);
  const hasOrderId = meta.searchParams.has('order_id');

  return isPathMatched && hasOrderId;
}

export const isAllowEnableTracker = (sdkToken, urlPath) => {
  // 特定店家
  // Fracora (zjy3gn2e)
  if (isFracora(sdkToken)) {
    return isInFracoraCheckoutPage(urlPath);
  }
  // チャイハネ (d2z75r69)
  if (isCayhane(sdkToken)) {
    return isInCayhaneCheckoutPage(urlPath);
  }
  // H&D (x2rylgjo)
  if (isHd(sdkToken)) {
    return isInHdCheckoutPage(urlPath);
  }
  // BRANSHES (z2qngl2m)
  if (isBranshes(sdkToken)) {
    return isInBranshesCheckoutPage(urlPath);
  }
  // RANDA (w27g7gx2)
  if (isRanda(sdkToken)) {
    return isInRandaCheckoutPage(urlPath);
  }
  
  // 非特定店家
  return true;
}
