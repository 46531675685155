import { SDK_TOKEN } from '@/constants.js';

// 這家不用 tracker 認列來計費，但想要其他 tracker 拿到的指標
// jira: https://17media.atlassian.net/browse/HU-4160
// slack: https://17media.slack.com/archives/C0158M21092/p1665046638856249?thread_ts=1665021857.913499&cid=C0158M21092

export const isGladdjpCheckoutPage = (href) => {
  const pathPattern = /gladd[.]jp[/]checkout[/]success/;
  const isPathMatched = Boolean(href.match(pathPattern));

  return SDK_TOKEN === '323vvw8j' && isPathMatched;
};
