import { v4 as uuid } from 'uuid';
import { ensureTracker } from '../runtime.js';
import { getSdkToken, sleep } from '../util.js';

/** @type {(sku: string, variant?: string?, sdkToken?: string) => string} */
export const toProductId = (sku, variant, sdkToken = 'debug-store') =>
  variant ? `${sdkToken}|${sku}|${variant}` : `${sdkToken}|${sku}`;

/** @type {(category: string | string[] ) => string[]} */
export const toProductCategories = (category) =>
  [].concat(category).filter(Boolean);

/** @type {(transactionId: string, sdkToken?: string) => string} */
export const toOrderId = (transactionId, sdkToken = 'debug-store') =>
  `${sdkToken}|${uuid()}|${transactionId}`;

/** @type {(transaction: Transaction?) => Promise<MatomoXHRResponse?>} */
export const purchase = async (transaction = {}) => {
  const tracker = await ensureTracker();
  if (!tracker) { return; }

  tracker.clearEcommerceCart();

  const { transaction_id: transactionId,
    value, /*currency,*/ tax, shipping, items,
  } = transaction;

  if (!Array.isArray(items) || !items.length) { return; }

  const sdkToken = getSdkToken();

  for (const product of items) {
    const { id: sku, name, category, variant, quantity, price } = product;
    const productId = toProductId(sku, variant, sdkToken);
    const productCategories = toProductCategories(category);
    tracker.addEcommerceItem(
      productId, name, productCategories, price, quantity);
  }
  const orderId = toOrderId(transactionId, sdkToken);

  return Promise.race([
    sleep(3000),
    new Promise((resolve) => {
      tracker.trackEcommerceOrder(orderId, value, 0, tax, shipping, 0, resolve);
    }),
  ]);
};
