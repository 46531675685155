import {
  insertMatomoScript,
} from './matomo.js';
import {
  isFirstParty,
  isThirdParty,
  isDebug,
  isLabelTarget,
  hashToVisitorId,
} from './util.js';
import {
  setupPostIdByTracker,
  setupStreamIdByTracker,
  setupSourceIdByTracker,
  pushInitQueue,
  ensureTracker,
  appendCrossDomainLinkingUrlParameter,
} from './runtime.js';

import {
  setupThirdPartyMessageChannel,
  setupFirstPartyMessageChannel,
} from './message-channel.js';

import { getFingerprintProId } from './fingerprintjs.js';

import { purchase } from './expose/purchase.js';
import {
  trackProductClickEvent,
  trackHandsupProductClickEvent,
  trackOneSevenProductClickEvent,
  setupCookieDomain,
} from './expose/action.js';
import {
  setupTrackerLoginId,
  setupTrackerPubnubId,
  setupTrackerPostId,
} from './expose/handsup-buyer.js';


import * as matomoFunctions from './matomo.js';
import * as domFunctions from './dom.js';
import * as utilFunctions from './util.js';
import * as runtimeFunctions from './runtime.js';
import * as fpFunctions from './fingerprintjs.js';

// 清除過期的 localStorage 資料
import expirableLocalStorage from './expirableLocalStorage.js';
expirableLocalStorage.clearExpired();

(async () => {
  if (isLabelTarget()) {
    insertMatomoScript();

    const [tracker, fpId] = await Promise.all([
      ensureTracker(),
      getFingerprintProId(),
    ]);

    await setupPostIdByTracker({ trackPageView: false });

    await setupStreamIdByTracker({ trackPageView: false });

    await setupSourceIdByTracker({ trackPageView: false });

    if (fpId) {
      tracker.setVisitorId(await hashToVisitorId(fpId));
    }

    tracker.trackPageView();
  }
})().catch((error) => {
  console.error(error);
});

if (isThirdParty()) {
  setupThirdPartyMessageChannel();
}
if (isFirstParty()) {
  setupFirstPartyMessageChannel();
}

const debugFunctions = {
  // debug
  ...matomoFunctions,
  ...domFunctions,
  ...utilFunctions,
  ...runtimeFunctions,
  ...fpFunctions,
};

export default {
  // message expose
  setupTrackerLoginId,
  setupCookieDomain,
  // merchant API
  purchase,
  trackProductClickEvent,
  trackHandsupProductClickEvent,
  trackOneSevenProductClickEvent,

  // runtime
  pushInitQueue,
  setupTrackerPostId,
  setupTrackerPubnubId,
  appendCrossDomainLinkingUrlParameter,
  ...(isDebug() ? debugFunctions : {}),
};
