import { isEmbedded, isFirstParty } from '@/util.js';
import TOPIC from '@/constants/message-topic.js';

// NOTE: 暴露給 buyer 使其可以隨時告訴外層或同層已經好的資訊

/** @type {(loginId: LoginId) => void} */
export const setupTrackerLoginId = (loginId) => {
  const payload = {
    type: 'HUT',
    topic: TOPIC.ANSWER_LOGIN_ID,
    body: loginId,
  };

  if (isEmbedded()) {
    window.parent.postMessage(payload, '*');
  }

  if (isFirstParty()) {
    window.postMessage(payload, window.location.origin);
  }
};

/** @type {(pubnubId: number) => void} */
export const setupTrackerPubnubId = (pubnubId) => {
  const payload = {
    type: 'HUT',
    topic: TOPIC.ANSWER_PUBNUB_ID,
    body: pubnubId,
  };

  if (isEmbedded()) {
    window.parent.postMessage(payload, '*');
  }

  if (isFirstParty()) {
    window.postMessage(payload, window.location.origin);
  }
};

/** @type {(postId: number) => void} */
export const setupTrackerPostId = (postId) => {
  const payload = {
    type: 'HUT',
    topic: TOPIC.ANSWER_POST_ID,
    body: postId,
  };

  if (isEmbedded()) {
    window.parent.postMessage(payload, '*');
  }

  if (isFirstParty()) {
    window.postMessage(payload, window.location.origin);
  }
};
