import { toFiniteNumberOrNull } from './util.js';

// NOTE: PREFIX_ID 依不同系統給予不同的前綴詞
const PREFIX_ID = 'hut-';
const DEFAULT_EXPIRATION = new Date(9e12).getTime();

const toExpirableStorageKey = (key) => PREFIX_ID + key;

// NOTE：
// 在某些裝置或瀏覽器，可能會被設定禁用 localStorage，
// 當使用 localStorage 時，
// 就會直接噴 Uncaught DOMException: The operation is insecure.
// 導致無法正常執行，故籍由此函式先進行測試。
/** @type { boolean } */
const  isLocalStorageSupported = (() => {
  try {
    const storage = window.localStorage;
    storage.getItem('hut-isLocalStorageSupported');
    return true;
  } catch {
    return false;
  }
})();

/** @type { (key: string, value: unknown, expired?: number) => void } */
const set = (key, value, expired = DEFAULT_EXPIRATION) => {
  const item = {
    value: value,
    expired,
  };

  localStorage.setItem(toExpirableStorageKey(key), JSON.stringify(item));
};

/** @type { (key: string, defaultValue?: T) => {value: T, expired: number} | null } */
const get = (key, defaultValue = null) => {
  const storageItemRaw = localStorage.getItem(toExpirableStorageKey(key));

  // localStorage 無此項目，直接回傳 defaultValue
  if (!storageItemRaw) {
    return {
      value: defaultValue,
      expired: DEFAULT_EXPIRATION,
    };
  }

  try {
    const store = JSON.parse(storageItemRaw);
    const storeExpired = toFiniteNumberOrNull(store?.expired);

    // NOTE: localStorage 有取到值，但內容並無 expired 值，可能是和之前的 Key 撞名。
    if (storeExpired === null) {
      return {
        value: store,
        expired: DEFAULT_EXPIRATION,
      };
    }

    const now = Date.now();
    let storeValue = store?.value ?? null;

    if (now > storeExpired) {
      storeValue = null;
    }

    return {
      value: storeValue,
      expired: storeExpired,
    };
  } catch {
    return null;
  }
};

/** @type { (key: string) => void } */
const remove = (key) => {
  localStorage.removeItem(toExpirableStorageKey(key));
};

const clearExpired = () => {
  const now = Date.now();

  for (const key of Object.keys(localStorage)) {
    if (!key.startsWith(PREFIX_ID)) { continue; }

    const storageItemRaw = localStorage.getItem(key);
    if (storageItemRaw === null) { continue; }

    try {
      const store = JSON.parse(storageItemRaw);
      const storeExpired = toFiniteNumberOrNull(store?.expired);
      if (storeExpired === null) { continue; }

      if (now > storeExpired) {
        localStorage.removeItem(key);
      }
    // silent catch 是避免在 for 時中斷刪除使該清除的紀錄未被清除
    // eslint-disable-next-line no-empty
    } catch {}
  }
};

export default {
  isLocalStorageSupported,
  set,
  get,
  remove,
  clearExpired,
};
